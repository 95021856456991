import { mapState } from 'vuex';
export default {
    computed:{
        ...mapState(['user']),
        isAdmin(){
            if(!this.user) return true;
            const roles = [1,2]
            return roles.includes(+this.user.role)
        }
    },
}


// import userRestriction from '@/mixins/user-restriction.js'

// mixins:[userRestriction],